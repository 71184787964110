import React, { useEffect, useContext, useState } from "react";
import useWPFetch from "../hooks/useWPFetch";
import Banner from "./Banner";
import Posts from "./Posts";
import PostsContext from "../context/PostsContext";
import TitleObserver from "./TitleObserver";
import MetaTags from "./MetaTags";
import { titleCase } from "../utils/utilities";
import LoadingPost from "./LoadingPost";

const PostsPageRenderer = (props) => {
  const loaderStyle = {
    width: "90%",
    margin: "0 auto",
    marginTop: "40px",
    alignItems: "start",
    justifyContent: "start",
    backgroundRepeat: "no-repeat",
  };
  const context = useContext(PostsContext);

  let postContext;
  let wp_postCategoryId;
  // let numOfPosts = 0;

  switch (props.pageCategory) {
    case "essays":
      postContext = context.essays;
      wp_postCategoryId = 69;
      break;

    case "poetry":
      postContext = context.poetry;
      wp_postCategoryId = 71;
      break;

    case "stories":
      postContext = context.stories;
      wp_postCategoryId = 68;
      break;

    case "blog":
      postContext = context.blog;
      wp_postCategoryId = 70;
      break;

    case "":
      postContext = context.home;
      wp_postCategoryId = "";
      break;

    case "tags":
      postContext = null;
      wp_postCategoryId = `wp-json/wp/v2/posts/?tags=${props.tagId}&per_page=50`;
      break;

    case "series":
      postContext = props.seriesPosts;
      wp_postCategoryId = `wp-json/wp/v2/posts/?tags=${props.tagId}&per_page=50`;
      break;

    default:
      postContext = context.home;
      wp_postCategoryId = "";
  }

  const [fetchDataList, setFetchDataList] = useState([]);

  const toFetchData =
    postContext === null || fetchDataList.length > 0 ? true : false;

  const [data, numPostsTotal] = useWPFetch(
    wp_postCategoryId,
    toFetchData,
    fetchDataList.length
  );

  const pageCategory = props.pageCategory;
  let thePosts = props.tagsData
    ? props.tagsData
    : toFetchData
    ? fetchDataList
    : postContext;
  fetchDataList.length === numPostsTotal &&
    fetchDataList.length !== 0 &&
    context.updatePosts(fetchDataList, pageCategory);

  useEffect(() => {
    data !== null && setFetchDataList([...fetchDataList, data]);
  }, [data]);

  let thePostsList = [];

  const multiPostGroupTitle = (ogTitle) => {
    const searchIndex = ogTitle.indexOf("&#");
    if (searchIndex >= 0 && ogTitle.indexOf(";") > searchIndex + 2) {
      // console.log(ogTitle.slice(0, searchIndex - 1).trim());
      return ogTitle.slice(0, searchIndex - 1).trim();
    } else {
      return ogTitle.slice(0, ogTitle.length - 1).trim();
    }
  };
  // GROUPING POSTS WITH MULTIPLE PARTS TOGETHER
  // BY MAKING LISTS OF INSIDE THE ALL POSTS LIST.
  const makeMultiPartPostsGroup = () => {
    thePosts.forEach((post) => {
      const indexOfPart = post.title.rendered.toLowerCase().indexOf("part");
      if (
        indexOfPart >= 0 &&
        parseInt(post.title.rendered[indexOfPart + 5]) !== NaN &&
        props.pageCategory !== "series"
      ) {
        const postTitle = post.title.rendered.slice(0, indexOfPart);
        let toMakeNewGroup = true;
        thePostsList.forEach((item) => {
          // try {
          //   console.log(
          //     item[0].title.rendered.slice(0, indexOfPart).trim() ===
          //       postTitle.trim()
          //   );
          // } catch (error) {
          //   console.log(error);
          // }

          if (
            typeof item[0] === "object" &&
            item[0].title.rendered.slice(0, indexOfPart).trim() ===
              postTitle.trim()
          ) {
            item.push(post);
            toMakeNewGroup = false;
          }
        });
        if (toMakeNewGroup) {
          post.revisedTitle = multiPostGroupTitle(postTitle);
          const postItemList = [post];
          thePostsList = [...thePostsList, postItemList];
          // thePosts = thePostsList;
        }
      } else {
        thePostsList = [...thePostsList, post];
        // thePosts = thePostsList;
      }
    });
  };

  return (
    <div>
      <MetaTags
        pageCategory={titleCase(pageCategory)}
        description="xxxxxxxxxx"
      />
      {pageCategory !== "" && pageCategory !== "series" && (
        <div>
          <TitleObserver
            pageCategory={
              props.observerTitle ? props.observerTitle : pageCategory
            }
          />
          {props.pageCategory === "tags" ? (
            <div></div>
          ) : (
            <Banner
              pageURL={pageCategory}
              seriesPosts={
                pageCategory === "series" ? props.seriesPosts : undefined
              }
            />
          )}
        </div>
      )}
      {thePosts !== null && thePosts.length > 0 ? (
        <div className="container">
          {thePosts
            .sort((a, b) => (a.index < b.index ? -1 : 1))
            .map((post) => (
              <Posts key={post.id} post={post} />
            ))}
          {fetchDataList.length < numPostsTotal &&
            [...Array(numPostsTotal - fetchDataList.length).keys()].map(
              (index) => (
                <div key={index}>
                  <LoadingPost />
                </div>
              )
            )}
        </div>
      ) : (
        <div className="container">
          <LoadingPost />
          <LoadingPost />
          <LoadingPost />
          <LoadingPost />
          {/* <LoadingPost /> */}
          {/* <div style={pageCategory === "" ? loaderStyle : {}}></div> */}
        </div>
      )}
    </div>
  );
};

export default PostsPageRenderer;
