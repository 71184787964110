import React, { useState, useEffect, useContext, useMemo } from "react";
import PostTags from "./PostTags";
import ReactGA from "react-ga";
import PostsContext from "../context/PostsContext";
import FontSettings from "./FontSettings";
import { Redirect } from "react-router-dom";

const Post = ({ location, postFontSize, setPostFontSize }) => {
  const [postData, setPostsData] = useState([]);
  const [toRedirect, setToRedirect] = useState(false);
  const [fontSize, setFontSize] = useState(postFontSize);
  const context = useContext(PostsContext);

  const contentFontSize = { fontSize: fontSize + "px" };

  const readTime = (stringOfWords) => {
    const wordCount = stringOfWords.split(" ").length;
    const readTime = Math.ceil(wordCount / 300);
    return readTime;
  };

  const dataSource = () => {
    const source =
      baseURL().indexOf("localhost") > 0
        ? "https://pabuspress.co.za/pp"
        : "https://pabuspress.co.za/pp";
    return source;
  };

  const baseURL = () => {
    const url = document.URL;
    const index = url.indexOf("://");
    const httpLessUrl = url.slice(index + 3);
    const urlExtensionIndex = httpLessUrl.indexOf("/");
    const baseURL =
      url.slice(0, index + 3) + httpLessUrl.slice(0, urlExtensionIndex);
    return baseURL;
  };

  const postId = () => {
    const index = document.URL.indexOf("/post/");
    const postId = document.URL.slice(index + 6);
    return postId;
  };

  const getPostData = async () => {
    try {
      const apiCall = await fetch(
        `${dataSource()}/wp-json/wp/v2/posts/${postId()}`
      );
      if (apiCall.status >= 400) {
        setToRedirect(true);
      } else {
        const json = await apiCall.json();
        postImage(json);
      }
    } catch (error) {
      setToRedirect(true);
    }
  };

  const postImage = async (post) => {
    if (post.featured_media !== 0) {
      const imageLink = `${dataSource()}/wp-json/wp/v2/media/${
        post.featured_media
      }`;

      const apiCall2 = await fetch(imageLink);
      const postDatat = await apiCall2.json();
      post.image = postDatat.media_details.sizes.full.source_url;
      setPostsData(post);
    } else {
      post.image =
        "https://pabuspress.co.za/pp/wp-content/uploads/2020/03/db782c8f-52e7-393a-ad98-3351296ad5be.jpg";
      setPostsData(post);
    }
  };

  const getContent = (data) => {
    const postContent = document.querySelector(".post-content");
    postContent.innerHTML = data;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.initialize("UA-166633723-1");
    try {
      console.log(postData.title.rendered);
      ReactGA.pageview(postData.title.rendered);
    } catch (error) {
      ReactGA.pageview("post");
    }

    if (location.posts) {
      setPostsData(location.posts.post);
    } else {
      getPostData();
    }
  }, []);

  console.log(toRedirect);
  const style = { overflowX: "hidden" };
  const posta = postData;
  if (posta.length === 0) {
    return (
      <div className="loading-bg">
        {toRedirect && <Redirect to="" />}
        <div></div>
      </div>
    );
  }

  return (
    <>
      <FontSettings
        setFontSize={setFontSize}
        setPostFontSize={setPostFontSize}
      />
      <div style={style} className="post">
        <div className="meta-tags">
          <div className="meta-details">
            Estimated Read Time: {readTime(posta.content.rendered)} Min
          </div>
          <div className="meta-details">Created: {posta.date.slice(0, 10)}</div>
          <div className="meta-details">Written by D.K Pabu</div>
        </div>
        <div
          className="post-content"
          style={contentFontSize}
          dangerouslySetInnerHTML={{ __html: posta.content.rendered }}
        ></div>
        <div></div>
      </div>
      <PostTags tags={posta.tags} />
    </>
  );
};

export default Post;
