import React, { useEffect } from "react";
import { PageView, initGA } from "./Tracking";
import Banner from "./Banner";

const Bio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(document.documentElement.scrollHeight);
    console.log(document.documentElement.clientHeight);
  }, []);

  const styles = {
    textAlign: "center",
    paddingTop: "50px",
  };

  return (
    <div style={styles}>
      <div>
        STILL UNDER
        <br />
        CONSTRUCTION
      </div>
      <br />
      <br />
      <div>danielkapenapabu@gmail.com </div>
    </div>
  );
};

export default Bio;
