import React, { useEffect } from "react";
import useWPFetchTagNames from "../hooks/useWPFetchTagNames";
import { Link } from "react-router-dom";

const PostTags = (props) => {
  const tags = useWPFetchTagNames(props.tags);

  if (tags.length !== 0) {
    return (
      <div className="tags-wrapper">
        <div className="tags-heading">Post Tags</div>
        <div className="post-tags-container">
          <div>
            <div className="tags">
              {tags.map((tag) => (
                <div key={"tahffg" + tag.id}>
                  <Link key={tag.tagId} to={`/tags/${tag.id}`}>
                    <div className="tag">{tag.name.toUpperCase()}</div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default PostTags;
