import React, { useState } from "react";

const FontSettings = ({ setFontSize, setPostFontSize }) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const openPanelClass = panelOpen ? " font-panel-open" : "";
  const opacityTransition = panelOpen ? " font-size-label-off" : "";

  return (
    <div className={`font-settings-container${openPanelClass}`}>
      <div
        onClick={() => setPanelOpen(true)}
        className={`font-size-container${opacityTransition}`}
      >
        <h5 className="font-size-text">Font Size</h5>
      </div>
      <div className="font-btns-container">
        <div>
          <h2 onClick={() => setPanelOpen(false)} className="font-btns-close">
            x
          </h2>
          <div
            onClick={() => {
              setFontSize((prevSize) => (prevSize <= 35 ? prevSize + 1 : 36));
              setPostFontSize((prevSize) =>
                prevSize <= 35 ? prevSize + 1 : 36
              );
            }}
            className="font-up"
          >
            A+
          </div>
          <div
            onClick={() => {
              setFontSize((prevSize) => (prevSize > 13 ? prevSize - 1 : 12));
              setPostFontSize((prevSize) =>
                prevSize > 13 ? prevSize - 1 : 12
              );
            }}
            className="font-down"
          >
            A-
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontSettings;
