import React from "react";
import {
  FaFacebookF,
  FaSnapchat,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";

const Socials = () => {
  const fontSize = "20px";
  return (
    <div>
      <div className="socials-container">
        <a href="https://www.twitter.com/dkpabu/" target="_blank">
          <FaTwitter className="socials-t" size={fontSize} />
        </a>
        <a href="https://instagram.com/dkpabu/" target="_blank">
          <FaInstagram className="socials-i" size={fontSize} />
        </a>
      </div>
    </div>
  );
};

export default Socials;
