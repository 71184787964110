import React, { useContext, useState } from "react";
import PostsContext from "../context/PostsContext";
import emailjs from "emailjs-com";

const MailModal = ({ newsletterToggle }) => {
  const context = useContext(PostsContext);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(false);
  const [stateImage, setStateImage] = useState("check.png");
  const [formError, setFormError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setStateImage("IMG_1950.GIF");
    setLoading(true);
    setFormError(false);

    emailjs
      .sendForm("pp", "new_member", e.target, "user_jw0mWcZ6PAjV4NtBWu2r9")
      .then(
        (result) => {
          setStateImage("check.png");
          setTimeout(() => {
            setLoading(false);
            setFormError(false);
            newsletterToggle();
          }, 1000);
        },
        (error) => {
          setLoading(false);
          setFormError(true);
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="newsletter-form-wrapper">
      <div className="newsletter-form-container">
        <h4 className="newsletter-close-btn" onClick={newsletterToggle}>
          x
        </h4>
        <h2 className="newsletter-form-heading">Join Newsletter</h2>
        {formError && (
          <p className="form-error">An error happened, please try again</p>
        )}

        {!loading ? (
          <div className="form-loading-container">
            <img width="100px" src={require(`../img/${stateImage}`)} />
          </div>
        ) : (
          <form onSubmit={sendEmail} className="newsletter-form">
            <label>Name</label>
            <input
              type="text"
              placeholder="Please enter your name"
              name="memberName"
              required
            />
            <label>Email</label>
            <input
              type="email"
              placeholder="Please enter your email"
              name="memberEmail"
              required
            />
            <button
              type="submit"
              name="submit-btn"
              className="newsletter-submit-btn"
            >
              Join
            </button>
          </form>
        )}
      </div>
      <div className="modal-overlay" onClick={newsletterToggle}></div>
    </div>
  );
};

export default MailModal;
