import React, { useEffect } from "react";
import PostsPageRenderer from "./PostPageRenderer";

const Stories = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // initGA();
    // PageView();
  }, []);

  return <PostsPageRenderer pageCategory="stories" />;
};

export default Stories;
