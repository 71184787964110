import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PostPageRenderer from "./PostPageRenderer";

const PostsPage = (props) => {
  const tagId = () => {
    const index = document.URL.indexOf("/tags/");
    const tagId = document.URL.slice(index + 6);
    return tagId;
  };
  const [tagName, setTagName] = useState("...");
  const [toRedirect, setToRedirect] = useState(false);

  const titleCase = (str) => {
    try {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    } catch (error) {
      return str;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch("https://pabuspress.co.za/pp/wp-json/wp/v2/tags/" + tagId())
      .then((res) => res.json())
      .then((data) => setTagName(data.name))
      .catch(() => setToRedirect(true));
  }, []);

  console.log(toRedirect);
  return (
    <div>
      {!tagName && <Redirect to="" />}
      <h3 className="tag-page-heading">
        {"Posts About " + titleCase(tagName)}
      </h3>
      <PostPageRenderer
        observerTitle={tagName}
        pageCategory="tags"
        tagId={tagId()}
      />
    </div>
  );
};
export default PostsPage;
