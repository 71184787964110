import React, { useState } from "react";
import Bio from "./Bio.js";
import Post from "./Post.js";
import Nav from "./Nav.js";
import Footer from "./Footer.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Stories from "./Stories.js";
import Essays from "./Essays.js";
import Poetry from "./Poetry.js";
import Blog from "./Blog.js";
import HomeContent from "./HomeContent.js";
import PostsContext from "../context/PostsContext.js";
import PostsPage from "./PostsPage.js";
import PostSeries from "./PostSeries.js";
import Socials from "./Socials.js";
import HomePage from "./HomePage.js";

const App = () => {
  const [stories, setStories] = useState(null);
  const [essays, setEssays] = useState(null);
  const [poetry, setPoetry] = useState(null);
  const [blog, setBlog] = useState(null);
  const [home, setHome] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [postFontSize, setPostFontSize] = useState(20);

  const updatePosts = (data, pageCategory) => {
    pageCategory.toLowerCase() === "essays" && setEssays(data);
    pageCategory.toLowerCase() === "stories" && setStories(data);
    pageCategory.toLowerCase() === "poetry" && setPoetry(data);
    pageCategory.toLowerCase() === "blog" && setBlog(data);
    pageCategory.toLowerCase() === "" && setHome(data);
  };

  return (
    <PostsContext.Provider
      value={{
        essays,
        stories,
        poetry,
        blog,
        home,
        updatePosts,
        modalIsOpen,
        setModalIsOpen,
      }}
    >
      <Router>
        <Nav />
        <Switch>
          <Route path="/poetry" exact component={Poetry} />
          <Route path="/stories" exact component={Stories} />
          <Route path="/essays" exact component={Essays} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/bio" exact component={Bio} />
          <Route path="/series/:title" component={PostSeries} />
          <Route
            path="/post/:id"
            render={(props) => (
              <Post
                {...props}
                postFontSize={postFontSize}
                setPostFontSize={setPostFontSize}
              />
            )}
          />
          <Route path="/tags/:id" component={PostsPage} />
          <Route path="" component={HomePage} />
        </Switch>
        <Socials />
        <Footer />
      </Router>
    </PostsContext.Provider>
  );
};

export default App;
