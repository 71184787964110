import React, { useEffect, useContext, useState } from "react";
import PostsPageRenderer from "./PostPageRenderer";
// import MetaTags from "./MetaTags";

const Essays = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // initGA();
    // PageView();
  }, []);

  const pageCategory = "essays";
  const description = "xxxxxxxxxxxx";

  return (
    <div>
      {/* <MetaTags pageCategory="essays" description="xxxxxxxxxx" /> */}
      <PostsPageRenderer pageCategory="essays" />
    </div>
  );
};

export default Essays;
