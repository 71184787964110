import { useEffect, useState } from "react";

// useWPFetchTagNames take an array of WP tag numbers/id
// and returns an array with the tag name and associated Id

const useWPFetchTagNames = (tags) => {
  const [tagsNames, setTagsNames] = useState([]);
  useEffect(() => {
    if (tags && typeof tags[0] !== "number") {
      setTagsNames(tags);
    } else {
      let tagNames = [];
      const getTags = () => {
        tags.forEach(async (tag) => {
          const tagUrl = document.URL.toLowerCase().includes("localhost")
            ? `https://pabuspress.co.za/pp/wp-json/wp/v2/tags/${tag}`
            : `https://pabuspress.co.za/pp/wp-json/wp/v2/tags/${tag}`;

          const apiCall = await fetch(tagUrl);
          const tagJson = await apiCall.json();
          tagNames = [
            ...tagNames,
            tagJson,
            // { tagTitle: tagJson.name, tagId: tagJson.id },
          ];
          if (tagNames.length === tags.length) {
            setTagsNames(tagNames);
            return;
          }
        });
      };

      if (tags.length === 0) {
        return;
      } else {
        getTags();
      }
    }
  }, []);
  return tagsNames;
};

export default useWPFetchTagNames;
