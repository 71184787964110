import React, { useContext, useEffect } from "react";
import useWPFetch from "../hooks/useWPFetch";
import Banner from "./Banner";
import Posts from "./Posts";
import PostsContext from "../context/PostsContext";
import TitleObserver from "./TitleObserver";
import MetaTags from "./MetaTags";
import { PageView, initGA } from "./Tracking";
import PostsPageRenderer from "./PostPageRenderer";

const Poetry = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // initGA();
    // PageView();
  }, []);

  return <PostsPageRenderer pageCategory="poetry" />;
};

export default Poetry;
