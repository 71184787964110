import React, { useState } from "react";
import NavMenu from "./NavMenu";
import { Link } from "react-router-dom";
import MailModal from "./MailModal";

const Nav = () => {
  const [lightModeActive, setLightModeActive] = useState(true);
  const modePicture = lightModeActive
    ? "../img/moon12.svg"
    : "../img/sun123.svg";

  const navToggle = () => {
    const navOverlay = document.querySelector(".nav-menu-overlay");
    const navMenu = document.querySelector(".nav-menu-items");
    const hamburger = document.querySelectorAll(".hamburger-slice");

    navMenu.classList.toggle("nav-open");
    navOverlay.classList.toggle("nav-overlay-open");
    hamburger.forEach((hamburgerSlice) => {
      hamburgerSlice.classList.toggle("anim");
      hamburgerSlice.classList.toggle("hamburger-open");
    });
  };

  const modeToggle = () => {
    const html = document.querySelector("html");
    const modeContainer = document.querySelector(".mode-container");

    html.classList.toggle("dark");
    modeContainer.classList.toggle("dark-mode-container");
    setLightModeActive(!lightModeActive);
  };

  const newsletterToggle = () => {
    const modalOverlay = document.querySelector(".modal-overlay");
    const newsletterForm = document.querySelector(
      ".newsletter-form-container "
    );
    console.log(modalOverlay.classList.contains("nav-overlay-open"));
    modalOverlay.classList.toggle("nav-overlay-open");
    newsletterForm.classList.toggle("newsletter-form-active");
  };

  return (
    <div className="nav">
      <div className="logo-container">
        <Link to="/">
          <img src={require("../img/p.png")} />
          {/* <h3 className="page-title">Pabu's Press</h3> */}
        </Link>
      </div>

      <div className="nav-mode-btn-menu-container">
        <div className="join-btn-container">
          <a
            href="https://the-grownups-club-aka-straight-from-the-press.ck.page/c5fcf6e2d5"
            target="_blank"
          >
            <button
              className="page-newsletter-btn"
              // onClick={newsletterToggle}
            >
              Join Newsletter
            </button>
          </a>
        </div>

        <div style={{}} onClick={modeToggle} className="mode-container">
          {/* {lightMode ? "Night or Day" : "Day Night"} */}
        </div>
        <div onClick={navToggle} className="hamburger-container">
          <div className="hamburger-slice"></div>
          <div className="hamburger-slice"></div>
          <div className="hamburger-slice"></div>
        </div>
        <NavMenu navToggle={navToggle} />
        <MailModal newsletterToggle={newsletterToggle} />
      </div>
    </div>
  );
};

export default Nav;
