import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeContent from "./HomeContent";
import PostTags from "./PostTags";

const HomePage = () => {
  const [homeTags, setHomeTags] = useState({
    showAll: false,
    tags: [],
    scroll: null,
  });

  const numTagsShow = 20;
  useEffect(() => {
    fetch(
      "https://pabuspress.co.za/pp/wp-json/wp/v2/tags/?orderby=count&order=desc&per_page=100"
    )
      .then((res) => res.json())
      .then((data) => {
        if (data[0].name) {
          setHomeTags((prev) => {
            return { ...prev, tags: data };
          });
        }
      })
      .catch(() => console.log("error"));
  }, []);

  const tagsToShow =
    homeTags.showAll || homeTags.tags.length <= numTagsShow
      ? homeTags.tags
      : homeTags.tags.slice(0, numTagsShow);

  // if (homeTags.scroll) {
  //   console.log(homeTags.scroll);
  //   console.log(window.scrollY);
  //   window.scrollTo(0, homeTags.scroll);
  // }
  return (
    <div>
      <div className="home-content-container">
        <h3>
          Pabu's Press
          <br />A library of the mind.
        </h3>
        <Link to="/stories">
          <h2>Stories</h2>
        </Link>

        <Link to="/poetry">
          <h2>Poetry</h2>
        </Link>

        <Link to="/essays">
          <h2>Essays</h2>
        </Link>

        <Link to="/blog">
          <h2>Blog</h2>
        </Link>

        <Link to="/bio">
          <h2>About</h2>
        </Link>
      </div>
      <h3 className="latest-posts-home">latest posts</h3>
      <HomeContent />
      {homeTags.tags.length > 0 && (
        <>
          {/* <h3
            style={{ marginTop: "20px", marginBottom: "-60px" }}
            className="latest-posts-home"
          >
            post tags
          </h3> */}
          {tagsToShow.length <= numTagsShow && <PostTags tags={tagsToShow} />}
          {tagsToShow.length > numTagsShow && <PostTags tags={tagsToShow} />}
          <h3
            onClick={(e) => {
              let initScroll = window.scrollY;
              setHomeTags((prev) => {
                return {
                  ...prev,
                  showAll: !prev.showAll,
                  scroll: !prev.showAll ? initScroll : 0,
                };
              });
            }}
            className="see-more-tags-btn"
          >
            {homeTags.showAll ? "- show less" : "+ show more"}
          </h3>
        </>
      )}
    </div>
  );
};

export default HomePage;
