import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const MetaTags = (props) => {
  const pageCategory = props.pageCategory === "" ? "Home" : props.pageCategory;

  useEffect(() => {
    ReactGA.initialize("UA-166633723-1");
    ReactGA.pageview(pageCategory);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{pageCategory} | Pabu's Press</title>
        <meta name="description" content={`"${props.description}"`} />
        <meta
          name="keywords"
          content={`${pageCategory}, Literature, Action, South Africa`}
        />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
    </div>
  );
};

export default MetaTags;
