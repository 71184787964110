import React from "react";
import { Helmet } from "react-helmet";
import PostsPageRenderer from "./PostPageRenderer";

const HomeContent = (props) => {
  return (
    <div>
      <Helmet title="Home" description="This is a description" />
      <PostsPageRenderer pageCategory="" />
    </div>
  );
};
export default HomeContent;
