import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavMenu extends Component {
    render() {
        return (
            <div>
                <div onClick={this.props.navToggle} className='nav-menu-overlay'>
                </div>
                <div className='nav-menu-items'>
                    <ul onClick={this.props.navToggle}>
                        <Link to='/'>
                            <li>Home</li>
                        </Link>
                        
                        <Link to='/poetry'>
                            <li>Poetry</li>
                        </Link>

                        <Link to='/stories'>
                            <li>Stories</li>
                        </Link>

                        <Link to='/essays'>
                            <li>Essays</li>
                        </Link>

                        <Link to='/blog'>
                            <li>Blog</li>
                        </Link>

                        <Link to='/bio'>
                            <li>Bio</li>
                        </Link>
                    </ul>
                </div>
            </div>
        );
    }
}

export default NavMenu;
