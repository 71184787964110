import { useState, useEffect } from "react";

const useWPFetch = (categoryId, toFetchData = true) => {
  categoryId = `${categoryId}`;

  const urlBase = () => {
    return document.URL.toLocaleLowerCase().indexOf("localhost") >= 0
      ? "https://pabuspress.co.za/pp/"
      : "https://pabuspress.co.za/pp/";
  };

  let numOfPosts = 0;
  const endPoint = categoryId.includes("tags")
    ? categoryId
    : categoryId.length === 0
    ? "wp-json/wp/v2/posts"
    : `wp-json/wp/v2/posts/?categories=${categoryId}&per_page=100`;
  const url = urlBase() + endPoint;
  // const [isLoading, setIsLoading] = useState(false);
  const [numOfPostsFetched, setNumOfPostsFetched] = useState(0);
  const [fetchedData, setFetchedData] = useState(null);
  useEffect(() => {
    if (toFetchData === false) {
      return;
    }
    // setIsLoading(true);
    console.log(toFetchData);
    console.log("Sending http request");
    const postData = async (url) => {
      const apiCall = await fetch(url);
      const json = await apiCall.json();
      setNumOfPostsFetched(json.length);
      postImages(json);
    };

    postData(url);

    const postImages = (data) => {
      let numOfPostsLoaded = 0;
      data.map(async (post, index) => {
        if (post.featured_media !== 0) {
          const imageLink = `${urlBase()}wp-json/wp/v2/media/${
            post.featured_media
          }`;
          const apiCall2 = await fetch(imageLink);
          const postDatat = await apiCall2.json();
          try {
            post.image = postDatat.media_details.sizes.medium
              ? postDatat.media_details.sizes.medium.source_url
              : postDatat.media_details.sizes.full.source_url;
          } catch {
            post.image =
              "https://pabuspress.co.za/pp/wp-content/uploads/2020/03/db782c8f-52e7-393a-ad98-3351296ad5be.jpg";
          }
          post.index = index;
          setFetchedData(post);
          numOfPostsLoaded += 1;
          if (numOfPostsLoaded === numOfPosts) {
            // setIsLoading(false);
          }
        } else {
          // console.log(post);
          post.image =
            "https://pabuspress.co.za/pp/wp-content/uploads/2020/03/db782c8f-52e7-393a-ad98-3351296ad5be.jpg";
          post.index = index;
          setFetchedData(post);
          if (numOfPostsLoaded === numOfPosts) {
            // setIsLoading(false);
          }
        }
      });
    };
  }, []);
  // const isDoneLoading =
  // numPostsFetched === numOfPostsFetched ? true : false;
  return [fetchedData, numOfPostsFetched];
};

export default useWPFetch;
