import React, { useEffect, Fragment } from "react";
import PostsPageRenderer from "./PostPageRenderer";
import MetaTags from "./MetaTags";
import { Helmet } from "react-helmet";

const Blog = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pageCategory = "blog";
  const description = "xxxxxxxxxxxx";
  return (
    <Fragment>
      {/* <MetaTags pageCategory="blog" description="xxxxxxxxxx" /> */}
      <Helmet>
        <title>{pageCategory} | Pabu's Press</title>
        <meta name="description" content={`"${description}"`} />
        <meta
          name="keywords"
          content={`${pageCategory}, Literature, Action, South Africa`}
        />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <script>console.log('summer')</script>
      </Helmet>
      <PostsPageRenderer pageCategory="blog" />
    </Fragment>
  );
};

export default Blog;
