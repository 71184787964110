import React from "react";

const LoadingPost = () => {
  return (
    <div className="loading-post-wrapper">
      <div className="loading-post-container">
        <div className="loading-post-image"></div>
        <div className="loading-post-ert"></div>
        <div className="loading-post-category"></div>
        <div className="loading-post-title"></div>
        <div className="loading-post-excerpt"></div>
      </div>
    </div>
  );
};

export default LoadingPost;
