import React, { Component } from "react";
import { Link } from "react-router-dom";
import TitleObserver from "./TitleObserver";

class Posts extends Component {
  excerptTruncate = function (text, limit) {
    if (text.length > limit) {
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === " " &&
          (text.charAt(i - 1) !== "," ||
            text.charAt(i - 1) !== "." ||
            text.charAt(i - 1) !== ";")
        ) {
          return text.substring(0, i) + "...</p>";
        }
      }
      return text.substring(0, limit) + "...";
    } else return text;
  };

  readTime = (stringOfWords) => {
    const wordCount = stringOfWords.split(" ").length;
    const readTime = Math.ceil(wordCount / 300);
    return readTime;
  };

  postCategory = (categories) => {
    const category = categories[0];
    if (category === 68) {
      return "Story";
    }
    if (category === 69) {
      return "Essay";
    }
    if (category === 70) {
      return "Blog Post";
    }
    if (category === 71) {
      return "Poem";
    }
  };

  headingFixer = (heading) => {
    if (heading.indexOf("&#038;") > 0) {
      heading = heading.replace("&#038;", "&");
    }
    if (heading.indexOf("&#039;") > 0) {
      heading = heading.replace("&#039;", "'");
    }
    if (heading.indexOf("&#8211;") > 0) {
      heading = heading.replace("&#8211;", "-");
    }
    if (heading.indexOf("&#8221;") > 0) {
      heading = heading.replace("&#8221;", '"');
    }
    return heading;
  };

  render() {
    let post = this.props.post;
    let groupPostClass = "";
    // if (typeof this.props.post[0] === "object") {
    //   post = this.props.post[0];

    //   post.revisedTitle = post.revisedTitle
    //     ? `${post.revisedTitle}`
    //     : this.props.post.title.rendered;
    //   groupPostClass = "post-container-series";
    // }
    return (
      <Link
        to={{
          pathname: `/post/${post.id}`,
          posts: {
            post: post,
          },
        }}
      >
        <div className="post-wrapper">
          <div className={`post-container ${groupPostClass}`} key={post.id}>
            <div className="post-image">
              <img src={post.image} alt="feature" className="feature-image" />
            </div>
            <div className="read-time-container">
              <p className="read-time">
                Estimated Read Time: {this.readTime(post.content.rendered)} Min
              </p>
            </div>
            <div className="post-category">
              {post.revisedTitle
                ? this.props.post.length +
                  " Part " +
                  this.postCategory(post.categories)
                : this.postCategory(post.categories)}
            </div>
            <div className="heading-container">
              <h3
                className="heading"
                dangerouslySetInnerHTML={{
                  __html: post.revisedTitle
                    ? post.revisedTitle
                    : post.title.rendered,
                }}
              >
                {/* {this.headingFixer(post.title.rendered)} */}
              </h3>
            </div>
            <div className="excerpt-container">
              <p
                className="excerpt"
                dangerouslySetInnerHTML={{
                  __html: this.excerptTruncate(post.excerpt.rendered, 150),
                }}
              ></p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
export default Posts;
