import { React, useEffect } from "react";
import PostsPageRenderer from "./PostPageRenderer";
import { Redirect } from "react-router-dom";

const PostSeries = (props) => {
  let redirec = false;

  useEffect(() => {
    try {
      const postsList = props.location.posts.post;
      redirec = false;
    } catch (error) {
      redirec = true;
    }
  }, []);

  return (
    <div>
      <PostsPageRenderer
        pageCategory="series"
        seriesPosts={props.location.posts.post}
      />
    </div>
  );
};

export default PostSeries;
