import React, { useEffect, useRef, useState } from "react";

const TitleObserver = (props) => {
  const [showBlackBanner, setShowBlackBanner] = useState(true);
  const observedItem = useRef(null);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  useEffect(() => {
    observer.observe(observedItem.current);
  }, [showBlackBanner]);

  const observerCallback = (entries) => {
    entries[0].isIntersecting
      ? setShowBlackBanner(true)
      : setShowBlackBanner(false);
  };
  const observer = new IntersectionObserver(observerCallback, options);
  console.log();
  //   observer.observe(observedItem);
  if (!showBlackBanner) {
    // console.log("s");
    return (
      <div ref={observedItem}>
        <div className="black-banner">
          <h3 className="black-banner-text">
            {props.pageCategory.toUpperCase()}
          </h3>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={observedItem}>
        <div
          style={{ display: "none", opacity: 0, pointerEvents: "none" }}
          className="black-banner"
        >
          {props.pageCategory.toUpperCase()}
        </div>
      </div>
    );
  }
};

export default TitleObserver;
