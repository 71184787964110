import React from "react";

const Banner = (props) => {
  const bannerImageHandler = (url) => {
    const style = {
      backgroundImage: `url("${bannerImage(url)}")`,
    };
    return style;
  };

  const bannerImage = (url) => {
    if (url === "stories") {
      return "https://pabuspress.co.za/pp/wp-content/uploads/2020/04/stories-2-scaled.jpg";
    } else if (url === "essays") {
      return "https://pabuspress.co.za/pp/wp-content/uploads/2020/05/joanna-kosinska-1_CMoFsPfso-unsplash-scaled.jpg";
    } else if (url === "poetry") {
      return "https://pabuspress.co.za/pp/wp-content/uploads/2020/04/Prose-Poems-Musings1.jpg";
    } else if (url === "blog") {
      return "https://pabuspress.co.za/pp/wp-content/uploads/2020/04/miscellaneous-scaled.jpg";
    }
  };

  const pageDescription = (url) => {
    if (url === "stories") {
      return "Narrative journeys from an overactive imagination.";
    } else if (url === "essays") {
      return "Written thoughts and reflections on various subjects from art to anime.";
    } else if (url === "poetry") {
      return "A repository of poems, prose and musings.";
    } else if (url === "blog") {
      return "Stuff about stuff.";
    }
  };

  return (
    <div>
      <div
        style={bannerImageHandler(props.pageURL)}
        className="banner-container"
      ></div>
      <h3 className="page-category">{props.pageURL.toUpperCase()}</h3>
      <p className="page-category-description">
        {pageDescription(props.pageURL)}
      </p>
    </div>
  );
};

export default Banner;
