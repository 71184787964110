import React, { Component } from "react";

class Footer extends Component {
  render() {
    const styles = {};
    return (
      <div className="footer-container" style={styles}>
        <h3 className="footer">{"Pabu Press 2020"}</h3>
      </div>
    );
  }
}

export default Footer;
